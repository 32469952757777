import { combineReducers } from 'redux-immutable';

import lista from './lista';
import filtroLivre from './filtro_livre';
import filtroEstruturado from './filtro_estruturado';
import detalhes from './detalhes';
import historico from './historico';
import detalhesProdutoServico from './detalhes_produto_servico';
import resumoLista from './resumo_lista';

export default combineReducers({
  lista, filtroLivre, filtroEstruturado, detalhes, historico, detalhesProdutoServico, resumoLista,
});
