// @flow
'use strict';

const FONT_FAMILY = { fontFamily: 'Open Sans, sans-serif' };

export const TITULO_STYLE = {
  textAnchor: 'start', verticalAnchor: 'end', fill: '#000000', fontSize: '29px', ...FONT_FAMILY,
};

export const LEGENDA_STYLE = { labels: { fontSize: 22, ...FONT_FAMILY } };

export const FONT_STYLE = { fontSize: '26px', ...FONT_FAMILY };

export const FONT_FAMILY_GRAFICO = { fontFamily: 'sans-serif, Gill Sans, Gill Sans MT, Ser­avek, Trebuchet MS' };

export const LARGULA_LINHA_GRAFICO = { data: { strokeWidth: 4 } };

export const SIMBOLO_STYLE = {
  textAnchor: 'end', verticalAnchor: 'start', fill: '#000000', fontSize: '22px', ...FONT_FAMILY_GRAFICO,
};

export const AXIS_STYLE = { tickLabels: { fontSize: '24px', ...FONT_FAMILY_GRAFICO } };

export const TRANSPARENTE_STYLE = {
  backgroundColor: 'rgba(255,255,255,0.2)', ...FONT_FAMILY_GRAFICO,
};

export const PADDING = { top: 90, left: 80, bottom: 80, right: 40 };

export const BLUE_COLOR = '#335CCC';
export const ORANGE_COLOR = '#D5654A';

export const COL_MD_4  = { xs: 12, sm: 6, md: 4, xl: 4 },
             COL_MD_3  = { xs: 12, sm: 4, md: 3, xl: 3 },
             COL_MD_1  = { xs: 1, sm: 1, md: 1, xl: 1 },
             COL_MD_10 = { xs: 10, sm: 10, md: 10, xl: 10 };

export const NFE_LEGENDA = [
  { name: 'Emitidas', symbol: { fill: BLUE_COLOR } },
  { name: 'Recebidas', symbol: { fill: ORANGE_COLOR } },
];

export const CTE_LEGENDA = [
  { name: 'Emitidos', symbol: { fill: BLUE_COLOR } },
  { name: 'Tomados', symbol: { fill: ORANGE_COLOR } },
];

export const ESTILO_UMA_LINHA = { WebkitLineClamp: 1, height: '20px' };

export const ULTIMA_NOTICIA = 3;

export const ESTILO_BOTAO_NOTICIA_UMA_LINHA = { height: '23px' };
