// @flow

import { combineReducers } from 'redux-immutable';

import lista from './lista';
import detalhes from './detalhes';
import historico from './historico';
import resumoLista from './resumo_lista';
import filtroLivre from './filtro_livre';
import manifestacao from './manifestacao';
import filtroEstruturado from './filtro_estruturado';
import modalManifestacaoLote from './modal_manifestacao_lote';
import detalhesProdutoServico from './detalhes_produto_servico';
import detalhesCartao from './detalhes_cartao';

export default combineReducers({
  lista, detalhes, historico, resumoLista, filtroLivre, manifestacao, filtroEstruturado,
  modalManifestacaoLote, detalhesProdutoServico, detalhesCartao,
});
