// @flow
'use strict';

import React from 'react';
import { Button, ControlLabel, Form, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { useEffectOnce } from 'lib/viewUtils';
import {
  openModalSelector,
  manifestacaoSelector,
  alertasSelector,
  botaoDesabilitadoSelector,
  alteraUrl,
  fechaModal,
  alteraManifestacao,
  desabilitaBotao,
} from 'reducers/nfe/manifestacao';

import Alertas from './alertas';
import Icone from '../../../common/icone';
import { InputField } from '../../../common/input';

type ModalProps = {
  handleSubmit : () => void,
  url : string,
}

export default function ModalManifestaNfe({ handleSubmit, url } : ModalProps) {
  const open              = useSelector(openModalSelector),
        botaoDesabilitado = useSelector(botaoDesabilitadoSelector),
        manifestacao      = useSelector(manifestacaoSelector),
        alertas           = useSelector(alertasSelector);

  const dispatch    = useDispatch(),
        fecharModal = React.useCallback(() => dispatch(fechaModal()), [dispatch]),
        sendForm    = React.useCallback(() => {
          dispatch(desabilitaBotao());
          handleSubmit();
        }, [dispatch, handleSubmit]);

  const manifestacaoCiencia        = React.useCallback(() => dispatch(alteraManifestacao('ciencia')), [dispatch]),
        manifestacaoConfirmada     = React.useCallback(() => dispatch(alteraManifestacao('confirmada')), [dispatch]),
        manifestacaoOpNaoRealizada = React.useCallback(() => dispatch(alteraManifestacao('operacao_nao_realizada')), [dispatch]),
        manifestacaoDesconhecida   = React.useCallback(() => dispatch(alteraManifestacao('desconhecida')), [dispatch]);

  useEffectOnce(() => {
    dispatch(alteraUrl(url));
  });

  return (
      <Modal show={ open } onHide={ fecharModal } dialogClassName="modal-lg">
        <Form onSubmit={ sendForm }>
          <Modal.Header closeButton>
            <Modal.Title>Manifestação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { alertas && <Alertas alertas={ alertas }/> }

            <p>Selecione a manifestação que deseja enviar à SEFAZ, e clique em “Enviar Manifestação” para confirmar.</p>
            <p className="c">
              <Button className={ manifestacao === 'ciencia' ? 'btn-default active' : 'btn-default' }
                      onClick={ manifestacaoCiencia }>
                <Icone nome="info-circle" estilo="fas">Ciência da operação</Icone>
              </Button> { ' ' }

              <Button className={ manifestacao === 'confirmada' ? 'btn-success active' : 'btn-success' }
                      onClick={ manifestacaoConfirmada }>
                <Icone nome="thumbs-up" estilo="fas">Confirmada</Icone>
              </Button> { ' ' }

              <Button className={ manifestacao === 'operacao_nao_realizada' ? 'btn-warning active' : 'btn-warning' }
                      onClick={ manifestacaoOpNaoRealizada }>
                <Icone nome="thumbs-down" estilo="fas">Operação não realizada</Icone>
              </Button> { ' ' }

              <Button className={ manifestacao === 'desconhecida' ? 'btn-danger active' : 'btn-danger' }
                      onClick={ manifestacaoDesconhecida }>
                <Icone nome="question-circle" estilo="fas">Desconhecida</Icone>
              </Button> { ' ' }
            </p>

            { manifestacao === 'operacao_nao_realizada' &&
            <>
              <ControlLabel>
                <abbr title="obrigatório">* </abbr>
                Manifestação Justificativa
              </ControlLabel>
              <InputField name="manifestacaoJustificativa"/>
            </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="default" onClick={ fecharModal }>Cancelar</Button>
            <Button type="button" bsStyle="primary" onClick={ sendForm } disabled={ botaoDesabilitado || !manifestacao }> Enviar
              Manifestação</Button>
          </Modal.Footer>
        </Form>
      </Modal>
  );
}
