'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.11 Download de NFSe Individual

Obtém o XML ou PDF de um NFSe específico.

#### Requisição a enviar

Para obter o XML ou PDF de um NFSe

    GET /api/v1/nfse/{id}/download.{formato}

#### Parâmetros

Parâmetro     | Conteúdo             | Obrigatoriedade
------------- | -------------------- | ---------------
\`id\`        | ID da NFSe desejada. | Obrigatório
\`formato\`   | \`xml\` ou \`pdf\`   | Opcional

#### Respostas possíveis

##### 200: dados da NFSe

Retorna o XML ou PDF da NFSe:

\`\`\`
<NFe xmlns="">
  <Assinatura> ... </Assinatura>
  <ChaveNFe> ... </ChaveNFe>
  <DataEmissaoNFe> ... </DataEmissaoNFe>
  ...
</NFe>
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita.

\`\`\`
{ "error": "Requisição inválida." }
\`\`\`

##### 404: não encontrado

Significa que o documento não existe ou o usuário não possui acesso.

\`\`\`
{ "error": "Documento inexistente ou usuário não autorizado." }
\`\`\`

##### 412: falha na pré-condição

Significa que o documento não possui XML válido.

\`\`\`
{ "error": "Documento não possui XML." }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Banco de dados fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfse/62b080477bbe81e57e06b5bc/download.pdf'
\`\`\``;

export default function DownloadNfse() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}