// @flow
'use strict';

import * as React from 'react';

import { formatHelper } from '../../../lib/viewUtils';
import { permiteDownload } from '../../sped/modal_download/util';

import EsqueletoModal from '../../common/esqueleto_modal';

type ModalExportacaoLoteProps = {
  open : bool,
  fechaModal : () => void,
  totalDocumentos : number,
  exportaLote : () => void,
  limiteDownload : number,
}

export default function ModalExportacaoLote({ open, fechaModal, totalDocumentos, exportaLote, limiteDownload } : ModalExportacaoLoteProps) {
  if (!permiteDownload(totalDocumentos, limiteDownload)) {
    return (
        <EsqueletoModal open={ open } onAccept={ fechaModal } onClose={ fechaModal } acceptLabel="Ok" hideCancel>
          <p>Você solicitou o download de { formatHelper.numberWithDelimiter(totalDocumentos) } documentos,
            que é superior ao limite de { formatHelper.numberWithDelimiter(limiteDownload) } documentos.</p>
          <p>Por favor, refine sua busca, de forma a reduzir a quantidade de documentos no mesmo download.</p>
        </EsqueletoModal>
    );
  }

  return (
      <EsqueletoModal open={ open } onClose={ fechaModal } onAccept={ exportaLote } acceptLabel="Confirmar">
        <p>Você solicitou o download dos documentos da busca atual,
          que compreende { formatHelper.numberWithDelimiter(totalDocumentos) } documentos.</p>
        <p>O progresso da operação poderá ser acompanhado pela opção <q>Downloads</q>, no menu lateral.</p>
        <p>Confirma a solicitação?</p>
      </EsqueletoModal>
  );
}
