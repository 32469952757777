// @flow
'use strict';

import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import type { Noticia } from './tabela_noticias';
import { ESTILO_BOTAO_NOTICIA_UMA_LINHA, ESTILO_UMA_LINHA, ULTIMA_NOTICIA } from '../constantes';
import { abreNoticiaNoModal } from '../../../../reducers/dashboard/modal_noticia';
import { RefIsOverflow } from './functions';

import NoticiaDashboard from './noticia_dashboard';

type NoticiaTextoProps = {
  noticia : Noticia,
  abreNoticiaNoModal : (item : ?Noticia) => void,
  numeroNoticia : number,
}

export function NoticiaTexto({ noticia, abreNoticiaNoModal, numeroNoticia } : NoticiaTextoProps) {
  const abreNoticia = React.useCallback(() => abreNoticiaNoModal(noticia), [abreNoticiaNoModal, noticia]);
  const ultimaNoticia  = numeroNoticia === ULTIMA_NOTICIA,
        estilo = ultimaNoticia ? ESTILO_UMA_LINHA : {};

  const ref = React.useRef();
  const isOverflow = RefIsOverflow(ref);

  const noticiaOcupaUmaLinha = ref.current && ref.current.scrollHeight < 30;
  const estiloBotaoNoticiaUmaLinha = noticiaOcupaUmaLinha ? ESTILO_BOTAO_NOTICIA_UMA_LINHA : {};

  return (
      <NoticiaDashboard>
        <Button className="botao-link-noticia overflow-ellipsis" bsStyle="link" onClick={ abreNoticia } style={ estiloBotaoNoticiaUmaLinha }>
          <p title={ isOverflow ? noticia.titulo : '' } style={ estilo } ref={ ref }>{ noticia.titulo }</p>
        </Button>
      </NoticiaDashboard>
  );
}

export default connect<*, *, *, *, *, *>(null, { abreNoticiaNoModal })(NoticiaTexto);