// @flow
'use strict';

import * as React from 'react';
import { Button } from 'react-bootstrap';

import { ESTILO_BOTAO_NOTICIA_UMA_LINHA, ESTILO_UMA_LINHA, ULTIMA_NOTICIA } from '../constantes';
import { RefIsOverflow } from './functions';

import NoticiaDashboard from './noticia_dashboard';

type NoticiaLinkProps = {
  titulo : string,
  link : string,
  numeroNoticia : number,
}

export default function NoticiaLink({ titulo, link, numeroNoticia } : NoticiaLinkProps) {
  const ultimaNoticia  = numeroNoticia === ULTIMA_NOTICIA,
        estilo = ultimaNoticia ? ESTILO_UMA_LINHA : {};

  const ref = React.useRef();
  const isOverflow = RefIsOverflow(ref);

  const noticiaOcupaUmaLinha = ref.current && ref.current.scrollHeight < 30;
  const estiloBotaoNoticiaUmaLinha = noticiaOcupaUmaLinha ? ESTILO_BOTAO_NOTICIA_UMA_LINHA : {};

  return (
      <NoticiaDashboard>
        <a href={ link && link.startsWith('http') ? link : `https://${ String(link) }` }
           target="_blank" rel="noopener noreferrer">
          <Button className="botao-link-noticia overflow-ellipsis" bsStyle="link" style={ estiloBotaoNoticiaUmaLinha }>
            <p title={ isOverflow ? titulo : '' } style={ estilo } ref={ ref }>{ titulo }</p>
          </Button>
        </a>
      </NoticiaDashboard>
  );
}
