// @flow
'use strict';

import { connect } from 'react-redux';

import { abreModal } from '../../../../../reducers/modal_exportacao_lote';

import BotaoExportacaoLoteNfse from './botao_exportacao_lote';

const mapDispatchToProps = {
  abreModalPdf: () => abreModal('pdf', 'nfse'),
  abreModalXml: () => abreModal('xml', 'nfse'),
}

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(BotaoExportacaoLoteNfse);
