// @flow
'use strict';

import { connect } from 'react-redux';

import { abreModal } from '../../../../../reducers/modal_exportacao_lote';

import BotaoExportacaoLoteCte from './botao_exportacao_lote_cte';

const mapDispatchToProps = {
  abreModalPdf: () => abreModal('pdf', 'cte'),
  abreModalXml: () => abreModal('xml', 'cte'),
}

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(BotaoExportacaoLoteCte);
