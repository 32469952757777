'use strict';
// @flow

import * as Immutable from 'immutable';
import { createSelector } from 'reselect';

import type { Action, State, Dispatch, GetState } from 'lib/types';
import * as alerts from './alerts';
import request from '../lib/request';
import { queryExportacaoSelectorNfe } from './nfe/lista';
import { queryExportacaoSelectorCte } from './cte/lista';
import { queryExportacaoSelectorNfce } from './nfce/lista';
import { queryExportacaoSelectorNfse } from './nfse/lista';
import { queryExportacaoSelectorCfe } from './cfe/lista';

export const ALTERA_TIPO             = 'modal_exportacao_lote/ALTERA_TIPO',
             ALTERA_TOTAL_DOCUMENTOS = 'modal_exportacao_lote/ALTERA_TOTAL_DOCUMENTOS',
             ALTERA_TIPO_DOCUMENTO   = 'modal_exportacao_lote/ALTERA_TIPO_DOCUMENTOS',
             ABRE_MODAL              = 'modal_exportacao_lote/ABRE_MODAL',
             FECHA_MODAL             = 'modal_exportacao_lote/FECHA_MODAL';

const INITIAL_STATE = Immutable.fromJS({
  tipo: null,
  open: false,
  totalDocumentos: 0,
  tipoDocumento: '',
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case ALTERA_TIPO:
      return state.set('tipo', action.tipo);
    case ALTERA_TOTAL_DOCUMENTOS:
      return state.set('totalDocumentos', action.totalDocumentos);
    case ALTERA_TIPO_DOCUMENTO:
      return state.set('tipoDocumento', action.tipoDocumento);
    case ABRE_MODAL:
      return state.set('open', true);
    case FECHA_MODAL:
      return state.set('open', false);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['modalExportacaoLote'], Immutable.Map());
export const openSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('open', false));
export const tipoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('tipo'));
export const totalDocumentosSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('totalDocumentos'));
export const tipoDocumentoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('tipoDocumento'));

// actions

function abrirModal() {
  return { type: ABRE_MODAL };
}

function fecharModal() {
  return { type: FECHA_MODAL };
}

// thunk actions

export function abreModal(tipo : string, tipoDocumento : string) {
  return async function(dispatch : Dispatch<*>) {
    await dispatch({ type: ALTERA_TIPO, tipo });
    await dispatch({ type: ALTERA_TIPO_DOCUMENTO, tipoDocumento });
    await dispatch(alteraTotalDocumentos());
    dispatch(abrirModal());
  };
}

function alteraTotalDocumentos() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const tipoDocumento = tipoDocumentoSelector(getState());

    await request.get(`/app/${tipoDocumento}/total`)
        .accept('json')
        .query(getQueryPorTipoDocumento(tipoDocumento, getState))
        .then(r => dispatch({ type: ALTERA_TOTAL_DOCUMENTOS, totalDocumentos: r.body.totalDocumentos }))
        .catch(e => {
          dispatch(alerts.ajaxError(`Erro ao verificar o total de documentos para download ${nomeTipoDocumento(tipoDocumento)}`, e));
        });
  };
}

export function fechaModal() {
  return function(dispatch : Dispatch<*>) {
    dispatch({ type: ALTERA_TIPO, tipo: null });
    dispatch({ type: ALTERA_TOTAL_DOCUMENTOS, totalDocumentos: 0 });
    dispatch({ type: ALTERA_TIPO_DOCUMENTO, tipoDocumento: '' });
    dispatch(fecharModal());
  };
}

export function exportaLote() {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const tipoDocumento = tipoDocumentoSelector(getState());

    const path = tipoSelector(getState()) === 'xml' ? `/app/${tipoDocumento}/download_xml_lote` : `/app/${tipoDocumento}/download_pdf_lote`;

    await dispatch(fechaModal());

    await request.post(path)
        .accept('json')
        .query(getQueryPorTipoDocumento(tipoDocumento, getState))
        .then(() => {
          dispatch(alerts.alert('info', 'Sua solicitação está sendo processada. Acompanhe através da tela de downloads.'));
        })
        .catch(e => dispatch(alerts.ajaxError('Erro ao enviar solicitação de download', e)));
  };
}

function nomeTipoDocumento(tipoDocumento : string) : string {
  switch (tipoDocumento) {
    case 'nfe':
      return 'NF-e';
    case 'cte':
      return 'CT-e';
    case 'nfce':
      return 'NFC-e';
    case 'nfse':
      return 'NFS-e';
    case 'cfe':
      return 'CF-e';
    default:
      return '';
  }
}

function getQueryPorTipoDocumento(tipoDocumento : string, getState : GetState) : any {
  switch (tipoDocumento) {
    case 'nfe':
      return queryExportacaoSelectorNfe(getState());
    case 'cte':
      return queryExportacaoSelectorCte(getState());
    case 'nfce':
      return queryExportacaoSelectorNfce(getState());
    case 'nfse':
      return queryExportacaoSelectorNfse(getState());
    case 'cfe':
      return queryExportacaoSelectorCfe(getState());
    default:
      return null;
    }
}
