// @flow
'use strict';

import * as React from 'react';
import { FormSection } from 'redux-form/lib/immutable';

import SelecaoTodosCte from './selecao_todos_cte';
import CamposDocumento from '../../common/campos_documento';
import { Input } from '../../../../../../common/input';

export default function CamposBasicos() {
  return (
      <FormSection name="dadosBasicos">
        <SelecaoTodosCte nomes={ ["dadosBasicos"] } titulo="Dados Básicos"/>
        <div className="grupo-checkbox">
          <CamposDocumento/>

          <Input name="tipoCte" label="Tipo do CT-e" checkbox/>
          <Input name="cfop" label="CFOP" checkbox/>
          <Input name="naturezaOperacao" label="Natureza da Operação" checkbox/>
          <Input name="tipoOperacaoIdEDescr" label="Operação (entrada ou saída)" checkbox/>
          <Input name="municipioEnvio" label="Município de Envio" checkbox/>
          <Input name="ufEnvio" label="UF de Envio" checkbox/>
          <Input name="modalDescr" label="Modal" checkbox/>
          <Input name="tipoServico" label="Tipo do Serviço" checkbox/>

          <Input name="municipioInicio" label="Município Início" checkbox/>
          <Input name="ufInicio" label="UF Início" checkbox/>
          <Input name="municipioFim" label="Município Fim" checkbox/>
          <Input name="ufFim" label="UF Fim" checkbox/>
          <Input name="caracTransporte" label="Característica do Transporte" checkbox/>
          <Input name="caracServico" label="Característica do Serviço" checkbox/>
          <Input name="tipoTomador" label="Tipo de Tomador" checkbox/>
          <Input name="papelTomador" label="Papel do Tomador" checkbox/>

          <Input name="situacaoSefaz" label="Situação na SEFAZ" checkbox/>
          <Input name="autorizadoEm" label="Data de Autorização" checkbox/>
          <Input name="numeroProtocolo" label="Protocolo de Autorização" checkbox/>
          <Input name="cobrDataVencimento" label="Data do Vencimento" checkbox/>
          <Input name="obsGerais" label="Obs. Gerais" checkbox/>
          <Input name="informacoesAdicionaisFisco" label="Inform. Adicionais Fisco" checkbox/>
        </div>
      </FormSection>
  );
}