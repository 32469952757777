// @flow
'use strict';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { jsSelector } from '../../../lib/viewUtils';

import { currentPage, queryExportacaoSelectorNfse, trocaPagina } from '../../../reducers/nfse/lista';
import { ordenacaoSelector, ordenaLista } from '../../../reducers/nfse/filtro_livre';
import { openSelector } from '../../../reducers/modal_exportacao_lote';
import { carregaDetalhes } from '../../../reducers/nfse/detalhes';
import { formatoValor } from '../../common/lista_documentos/functions';

import PaginationFooter from '../../common/pagination_footer_notas';
import DataTable, { Column } from '../../common/data_table';
import BotaoExportacaoLote from '../../common/botoes_exportacao_lote/botao_exportacao_lote';
import OrdenacaoPorCampo from './ordenacao_por_campo';
import ExportacaoLista from '../../documento/exportacao_lista';
import ModalExportacaoLote from './exportacao_lote/modal_exportacao_lote';

const dadosJsSelector = jsSelector(currentPage, {});

export default function Lista() {
  const dispatch = useDispatch();

  const ordenacao       = useSelector(ordenacaoSelector),
        open            = useSelector(openSelector),
        queryExportacao = useSelector(queryExportacaoSelectorNfse),
        dados           = useSelector(dadosJsSelector);

  const ordenaPorEmitidoEm = React.useCallback(() => dispatch(ordenaLista('emitido_em')), [dispatch]),
        ordenaPorNumero    = React.useCallback(() => dispatch(ordenaLista('numero')), [dispatch]),
        ordenaPorValor     = React.useCallback(() => dispatch(ordenaLista('valor')), [dispatch]),
        ordenaPorPrestador = React.useCallback(() => dispatch(ordenaLista('prestador')), [dispatch]),
        ordenaPorTomador   = React.useCallback(() => dispatch(ordenaLista('tomador')), [dispatch]),
        trocarPagina       = React.useCallback((n : number) => dispatch(trocaPagina(n)), [dispatch]),
        handleRowClick     = React.useCallback((nfse) => nfse && dispatch(carregaDetalhes(nfse.id)), [dispatch]);

  const { items = null, page, total, pageSize, limiteDownload } = dados || {};

  return (
      <>
        <DataTable className="table-documento  link-button table-striped table-bordered table-hover notas"
                   data={ items }
                   onRowClick={ handleRowClick } striped hover>
          <Column expr="emitidoEm" align="center" width="8%">
            <OrdenacaoPorCampo campo="emitidoEm" rotulo="Emissão" ordenacao={ ordenacao }
                               onClick={ ordenaPorEmitidoEm }/>
          </Column>
          <Column expr="numero" align="center" width="8%">
            <OrdenacaoPorCampo campo="numero" rotulo="Número" ordenacao={ ordenacao } onClick={ ordenaPorNumero }/>
          </Column>
          <Column expr="valor" align="center" formatter={ formatoValor } width="10%">
            <OrdenacaoPorCampo campo="valor" rotulo="Valor" ordenacao={ ordenacao } onClick={ ordenaPorValor }/>
          </Column>
          <Column expr="prestadorRazaoSocial">
            <OrdenacaoPorCampo campo="prestador" rotulo="Prestador" ordenacao={ ordenacao }
                               onClick={ ordenaPorPrestador }/>
          </Column>
          <Column expr="tomadorRazaoSocial">
            <OrdenacaoPorCampo campo="tomador" rotulo="Tomador" ordenacao={ ordenacao }
                               onClick={ ordenaPorTomador }/>
          </Column>
        </DataTable>

        <PaginationFooter page={ page } total={ total } pageSize={ pageSize } onPageChange={ trocarPagina }
                          hasLastButton={ false }>
          <BotaoExportacaoLote tipo="nfse"/>
          <ExportacaoLista tipo="nfse" query={ queryExportacao }/>
        </PaginationFooter>

        { open && <ModalExportacaoLote limiteDownload={ limiteDownload }/> }
      </>
  );
}
