// @flow
'use strict';

import * as React from 'react';
// eslint-disable-next-line import/no-unresolved
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';

import * as alerts from '../../../reducers/alerts';
import { jsSelector } from '../../../lib/viewUtils';
import { fetcher } from '../../../lib/api/fetch';

import { ordenaLista, tipoSelector, ordenacaoSelector } from 'reducers/cfe/filtro_livre';
import { queryExportacaoSelectorCfe, trocaPagina, urlSelector, carregandoSelector } from 'reducers/cfe/lista';
import { carregaDetalhes } from 'reducers/cfe/detalhes';
import { openSelector } from 'reducers/modal_exportacao_lote';
import { formatoValor, documentoNome, documentoComTooltip } from '../../common/lista_documentos/functions';

import PaginationFooter from '../../common/pagination_footer_notas';
import DataTable, { Column } from '../../common/data_table';
import OrdenacaoPorCampo from '../../common/ordenacao_por_campo';
import BotaoExportacaoLote from '../../common/botoes_exportacao_lote/botao_exportacao_lote';
import ModalExportacaoLote from './exportacao_lote/modal_exportacao_lote';

const ordenacaoJsSelector = jsSelector(ordenacaoSelector, { s: '', sd: '' });

const MSG_NENHUM_DOC               = 'Nenhum documento encontrado',
      MSG_NENHUM_DOC_COM_CRITERIOS = 'Nenhum documento encontrado com os critérios especificados';

export default function Lista() {
  const dispatch = useDispatch();

  const tipo            = useSelector(tipoSelector),
        ordenacao       = useSelector(ordenacaoJsSelector),
        queryAtual      = useSelector(queryExportacaoSelectorCfe),
        open            = useSelector(openSelector),
        carregando      = useSelector(carregandoSelector),
        url             = useSelector(urlSelector);

  const { data, error } = useSWR(url, fetcher);

  const { items = null, page, total, pageSize, limiteDownload } = data || {},
        abaEmitidas                             = tipo === 'emitidos';

  React.useEffect(() => {
    if (data && data.alerta && data.alerta.length > 0)
      dispatch(alerts.alert('danger', data.alerta, true));

    if (error)
      dispatch(alerts.ajaxError('Erro ao carregar lista de CF-e', error));
  }, [dispatch, data, total, error]);

  const ordenaPorEmitidoEm    = React.useCallback(() => dispatch(ordenaLista('emitido_em')), [dispatch]),
        ordenaPorSerieNumero  = React.useCallback(() => dispatch(ordenaLista('serie,numero')), [dispatch]),
        ordenaPorValorTotal   = React.useCallback(() => dispatch(ordenaLista('valor')), [dispatch]),
        ordenaPorEmitente     = React.useCallback(() => dispatch(ordenaLista('emit_nome,emit_cnpj')), [dispatch]),
        ordenaPorDestinatario = React.useCallback(() => dispatch(ordenaLista('dest_nome,dest_cnpj')), [dispatch]),
        trocarPagina          = React.useCallback((n : number) => dispatch(trocaPagina(n)), [dispatch]),
        handleRowClick        = React.useCallback((nfe) => nfe && dispatch(carregaDetalhes(nfe.id)), [dispatch]);


  const possuiFiltro = !!(queryAtual && queryAtual.q);

  return (
      <>
        <DataTable className="table-documento cfe link-button table-striped table-bordered table-hover notas"
                   noRecordsFoundMessage={ possuiFiltro ? MSG_NENHUM_DOC_COM_CRITERIOS : MSG_NENHUM_DOC }
                   data={ carregando ? null : items } onRowClick={ handleRowClick } striped hover>
          <Column expr="emitidoEm" align="center" width={ 85 }>
            <OrdenacaoPorCampo campo="emitidoEm" rotulo="Emissão" ordenacao={ ordenacao }
                               onClick={ ordenaPorEmitidoEm }/>
          </Column>
          <Column expr="serie,numero" align="center" colSpan={ 2 } widthCells={ [35, 65] }>
            <OrdenacaoPorCampo campo="serieNumero" rotulo="Série / Nº" ordenacao={ ordenacao }
                               onClick={ ordenaPorSerieNumero }/>
          </Column>
          <Column expr="valorTotal" align="center" formatter={ formatoValor } width={ 125 }>
            <OrdenacaoPorCampo campo="valorTotal" rotulo="Valor Total" ordenacao={ ordenacao }
                               onClick={ ordenaPorValorTotal }/>
          </Column>
          <Column expr="emitCnpj,emitNome" formatter={ abaEmitidas ? documentoComTooltip : documentoNome }
                  width={ abaEmitidas ? 135 : null }
                  align={ abaEmitidas ? 'center' : 'left' }>
            <OrdenacaoPorCampo campo="emitente" rotulo="Emitente" ordenacao={ ordenacao }
                               onClick={ ordenaPorEmitente }/>
          </Column>
          <Column expr="destCnpj,destNome" formatter={ abaEmitidas ? documentoNome : documentoComTooltip }
                  width={ abaEmitidas ? null : 135 }
                  align={ abaEmitidas ? 'left' : 'center' }>
            <OrdenacaoPorCampo campo="destinatario" rotulo="Destinatário" ordenacao={ ordenacao }
                               onClick={ ordenaPorDestinatario }/>
          </Column>
        </DataTable>

        <PaginationFooter page={ page } total={ total } pageSize={ pageSize } onPageChange={ trocarPagina }
                          hasLastButton={ false }>
          <BotaoExportacaoLote tipo="cfe"/>
        </PaginationFooter>

        { open && <ModalExportacaoLote limiteDownload={ limiteDownload }/> }
      </>
  );
}
