// @flow
'use strict';

import { connect } from 'react-redux';

import { abreModal } from '../../../../../reducers/modal_exportacao_lote';

import BotaoExportacaoLoteNfce from './botao_exportacao_lote_nfce';

const mapDispatchToProps = {
    abreModalPdf: () => abreModal('pdf', 'nfce'),
    abreModalXml: () => abreModal('xml', 'nfce'),
}

export default connect<*, *, *, *, *, *>(null, mapDispatchToProps)(BotaoExportacaoLoteNfce);
