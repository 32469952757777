'use strict';
// @flow

import * as Immutable from 'immutable';
import Cookies from 'universal-cookie';
import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import type { Action, Dispatch, GetState, State } from 'lib/types';

import * as Paths from '../../paths';
import { ordenacaoSelector, tipoSelector, opcaoResumoSelector } from './filtro_livre';
import { currentRouteSelector } from '../routing';
import type { IFiltro } from './filtro_livre';

export const LISTA_CARREGANDO        = 'cte/lista/LISTA_CARREGANDO',
             LISTA_CARREGADA         = 'cte/lista/LISTA_CARREGADA',
             ALTERA_LAST_PATH        = 'cte/lista/ALTERA_LAST_PATH',
             ALTERA_QUERY_EXPORTACAO = 'cte/lista/ALTERA_QUERY_EXPORTACAO',
             SET_URL                 = 'cte/lista/SET_URL';

const cookie = new Cookies();

const INITIAL_STATE = Immutable.fromJS({
  url: '',
  carregando: false,
  queryExportacao: {},
  lastPath: cookie.get('lastPathCte') || '/app/cte/lista/todos',
});

export default function reducer(state : any = INITIAL_STATE, action : Action = {}) {
  switch (action.type) {
    case LISTA_CARREGANDO:
      return state.set('carregando', true);
    case LISTA_CARREGADA:
      return state.set('carregando', false);
    case ALTERA_LAST_PATH:
      return state.set('lastPath', action.path);
    case ALTERA_QUERY_EXPORTACAO:
      return state.set('queryExportacao', action.queryExportacao);
    case SET_URL:
      return state.set('url', action.url);
    default:
      return state;
  }
}

// selectors

export const rootSelector = (state : State) => state.getIn(['cte', 'lista'], Immutable.Map());
export const urlSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('url'));
export const carregandoSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('carregando'));
export const lastPathSelector = createSelector<*, *, *, *>(rootSelector, (root) => root.get('lastPath', '/app/cte/lista/todos'));
export const queryExportacaoSelectorCte = createSelector<*, *, *, *>(rootSelector, (root) => root.get('queryExportacao'));

// actions

export function listaCarregando() {
  return { type: LISTA_CARREGANDO };
}

export function listaCarregada() {
  return { type: LISTA_CARREGADA };
}

export function setUrl(url : string) {
  return { type: SET_URL, url };
}

// thunk actions

export function trocaPagina(page : number) {
  return async function(dispatch : Dispatch<*>, getState : GetState) {
    const route            = currentRouteSelector(getState()),
          ordenacaoAtual   = ordenacaoSelector(getState()),
          opcaoResumoAtual = opcaoResumoSelector(getState()),
          query            = route.get('query').toJS();

    const filtro = {
      d: '', page: String(page), s: ordenacaoAtual.get('s'), sd: ordenacaoAtual.get('sd'), f: opcaoResumoAtual,
    };
    dispatch(carregaLista(query, filtro));
  };
}

export function carregaLista(query : any = {}, filtro : IFiltro) {
  return async function(dispatch : Dispatch<any>, getState : GetState) {
    const tipo = tipoSelector(getState()),
          url  = Paths.ctesFilter.lista[tipo](query, filtro);

    dispatch(push(url));

    const queryExportacao = query && query.q ? { d: tipo, q: query.q, f: filtro.f } : { d: tipo, f: filtro.f };

    await dispatch({ type: ALTERA_QUERY_EXPORTACAO, queryExportacao });

    await dispatch({ type: ALTERA_LAST_PATH, path: url });
    cookie.set('lastPathCte', url);

    await dispatch(listaCarregando());
    await dispatch(setUrl(url));
    await dispatch(listaCarregada());
  };
}
