// @flow
'use strict';

import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import Icone from '../../common/icone';
import BotaoExportacaoLoteCte from '../../cte/lista/exportacao_lote/botao_exportacao_lote';
import BotaoExportacaoLoteNfce from '../../nfce/lista/exportacao_lote/botao_exportacao_lote';
import BotaoExportacaoLoteNfe from '../../nfe/lista/exportacao_lote/botao_exportacao_lote';
import BotaoExportacaoLoteCfe from '../../cfe/lista/exportacao_lote/botao_exportacao_lote';
import BotaoExportacaoLoteNfse from '../../nfse/lista/exportacao_lote/botao_exportacao_lote';

type BotaoExportacaoLoteProps = {
  tipo : string,
}

export default function BotaoExportacaoLote({ tipo } : BotaoExportacaoLoteProps) {
  let botaoExportacao;

  switch (tipo) {
    case 'nfe':
      botaoExportacao = <BotaoExportacaoLoteNfe/>;
      break;
    case 'cte':
      botaoExportacao = <BotaoExportacaoLoteCte/>;
      break;
    case 'nfce':
      botaoExportacao = <BotaoExportacaoLoteNfce/>;
      break;
    case 'cfe':
      botaoExportacao = <BotaoExportacaoLoteCfe/>;
      break;
    case 'nfse':
      botaoExportacao = <BotaoExportacaoLoteNfse/>;
      break;
    default:
      botaoExportacao = null;
  }

  return (
      <>
        <Dropdown dropup id="botao-exportacao-lote">
          <Dropdown.Toggle bsStyle="primary" id="dropdownMenuButton">
            <Icone nome="cloud-download" estilo="fas">Download</Icone>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { botaoExportacao }
          </Dropdown.Menu>
        </Dropdown> { ' ' }
      </>
  );
}
