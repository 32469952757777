'use strict';

import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';

import admin from './admin';
import alertaCertificadoExpirado from './alerta_certificado_expirado';
import alerts from './alerts';
import consultaPortaria from './consultaPortaria';
import documento from './documento';
import nfe from './nfe';
import cte from './cte';
import nfse from './nfse';
import nfce from './nfce';
import cfe from './cfe';
import decisaoPortaria from './decisaoPortaria';
import emails from './emails';
import modalExportacaoLista from './modal_exportacao_lista';
import modalExportacaoLote from './modal_exportacao_lote';
import modal from './modal';
import municipios from './municipios';
import routing from './routing';
import sped from './sped';
import listaDocumentos from './lista_documentos';
import decisaoMercadoria from './decisaoMercadoria';
import dashboard from './dashboard';
import arquivo from './arquivo';
import ferramentas from './ferramentas';
import modeloRelatorio from './modelo_relatorio';
import recuperacao from './recuperacao';

export default combineReducers({
  admin,
  alertaCertificadoExpirado,
  alerts,
  consultaPortaria,
  decisaoPortaria,
  decisaoMercadoria,
  documento,
  nfe,
  cte,
  nfse,
  nfce,
  cfe,
  emails,
  modal,
  modalExportacaoLista,
  modalExportacaoLote,
  form,
  municipios,
  routing,
  sped,
  listaDocumentos,
  dashboard,
  arquivo,
  ferramentas,
  modeloRelatorio,
  recuperacao,
});
